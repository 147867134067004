
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";

export default defineComponent({
  name: "settlement-offers-table-component",
  components: {},
  props: {
    redirect: String,
    policySummary: Object,
  },
  data() {
    return {
      offers: [],
      folioItem: "",
    };
  },
  created() {
    this.getSettlmentOffers();
    this.displayDatatable();
  },
  methods: {
    // Get list of settlement offfers from all underwriters
    getSettlmentOffers() {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get("/settlement")
          .then(({ data }) => {
            // Assign data to variables
            this.offers = data.data;
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    addCommaToNumberString(amount) {
      return variables.addCommaToNumberString(amount);
    },
    approveSettlementOffer(id, publicId, type) {
      const submitButton1 = document.getElementById(id);
      this.folioItem = publicId;

      const mainType = type === "approve" ? "true" : "false";
      const message =
        type === "approve" ? "" : "Settlement offer was appproved by a broker.";

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.removeAttribute("data-kt-indicator");

          // Send personal information update request
          if (JwtService.getToken()) {
            const formData = new FormData();

            formData.append("isAccepted", mainType);
            formData.append("additionalComments", message);

            ApiService.setHeader();
            ApiService.post(`/settlement/${publicId}/respond`, formData)
              .then(() => {
                // variables.toastAlert(response.data.data.message, "success");
                variables.toastAlert(
                  "Settlement offer was accepted successfully.",
                  "success"
                );

                this.getSettlmentOffers();
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    },
    previewSettlementOfferDocument(id, publicId, uniqueFileName) {
      const submitButton1 = document.getElementById(id);

      if (submitButton1) {
        // Activate indicator
        submitButton1.setAttribute("data-kt-indicator", "on");

        // Delete cart item
        if (JwtService.getToken()) {
          ApiService.setHeader();
          ApiService.setResponseType("blob");
          ApiService.get(
            `${variables.CLAIMS_ROUTE}/${publicId}/document/${uniqueFileName}`
          )
            .then((response) => {
              submitButton1.removeAttribute("data-kt-indicator");
              // console.log(response.data);
              const blob = new Blob([response.data], {
                type: "application/octet-stream",
              });

              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = uniqueFileName;
              // link.download = `${fileName}.${extension}`;

              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            })
            .catch(function(error) {
              submitButton1.removeAttribute("data-kt-indicator");
              console.log(error);
              variables.toastAlert(error.response.data.error, "error");
            });
        }
      }
    },
    displayDatatable() {
      setTimeout(() => {
        //Add jQuery datatable
        $("#basicExample").DataTable({
          iDisplayLength: 10,
          language: {
            searchPlaceholder: "Search...",
            sSearch: "",
            lengthMenu: "_MENU_ items/page",
            // "lengthMenu": "Display _MENU_ records per page",
            zeroRecords: "No matching records found",
            // "info": "Showing page _PAGE_ of _PAGES_",
            infoEmpty: "No records available",
            infoFiltered: "(filtered from _MAX_ total records)",
          },
          processing: true,
          destroy: true,
        });
      }, 3000);
    },
  },
});
